import React from "react";
import styled from "styled-components";
import BackgroundImage from "../../images/background.png";
import Logo from "../../images/logo@3x.png";
import AppStore from "../../images/appstore.png";
import HomeScreenImage from "../../images/SeenHomeImage.png";
import {Helmet} from "react-helmet";

const Container = styled.div`
  background-color: #7236C9;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  color: #000;
  @media screen
  and (min-device-width: 1200px)
  and (-webkit-min-device-pixel-ratio: 1) {
    overflow-x: hidden;
  }
`;
const ContentWrapper = styled.div`
  display: flex;   
  padding: 0 30px;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1280px;
    gap: 30px;
  }
`
const ContentLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    max-width: 728px;
    align-items: center;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  @media (min-width: 1200px) {
    align-items: flex-start;
  }
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
`;
const ContentRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    margin-top: 48px;
    margin-left: 48px;
  }
  @media (max-width: 1200px) {
    max-width: 728px;
  }
`
const TitleText = styled.div`
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 20px;
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 32px;
    line-height: 105%;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1200px)
  and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 72px;
    line-height: 105%;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media screen
  and (min-device-width: 1200px)
  and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 80px;
    line-height: 84px;
    text-align: left;
  }
`;
const SubtitleText = styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.8;
  flex: none;
  flex-grow: 0;
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1200px)
  and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  @media screen
  and (min-device-width: 1200px)
  and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 22px;
    line-height: 33px;
    text-align: left;
  }
`;
const HomeScreenImg = styled.img`
  margin-bottom: 40px;
  width: 376px;
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 273px;
  }
`;
const HelpText= styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  opacity: 0.8;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const HelpTextMobile = styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  opacity: 0.8;
  margin-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: 1200px) {
    display: none;
  }
  @media (max-width: 1200px) {
    text-align: center;
  }
`;
const TermsWrapper = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    bottom: 0;
    left: calc((100vw - 1288px)/2);
    width: 1228px;
    padding-bottom: 24px;
  }
  @media (max-width: 1200px) {
    width: 100vw;
    margin-bottom: 24px;
    text-align: center;
  }
`;
const TermsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;    
  padding-left: 18px;
`;
const Terms = styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #FFFFFF;
  opacity: 0.8;
  display: flex;
  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;  
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export default function HomePage() {
  return (
    <>
        <Container>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap" rel="stylesheet" />
            <script src="https://widget.prefinery.com/widget/v2/mwohdsru.js" defer></script>
            <title>Seen - Authentic Reactions</title>
          </Helmet>
            <ContentWrapper>
            <ContentLeftWrapper>
              <img src={Logo} style={{
                       marginBottom: "48px",
                       width: "230px",
                     }}/>

                  <TitleText>Get authentic reactions from friends.</TitleText>
                  <SubtitleText>We aim to provide a more interactive and engaging social media experience that enables users to express themselves on a deeper level and connect with others in a more authentic way.</SubtitleText>
                    <div>
                    <a href="https://apps.apple.com/us/app/seen-authentic-reactions/id6445854955" target="_blank">
                        <img src={AppStore} style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "160px",
                        display: "block"
                    }}/></a>
                    </div>
                    <HelpText>If you need assistance or have any questions, please feel free to email us at <a style={{
                        fontWeight: 700,
                        color: "#FFFFFF"
                    }} target="_blank" href="mailto: help@seen.lol">help@seen.lol</a>.</HelpText>
            </ContentLeftWrapper>
            <ContentRightWrapper>
                <HomeScreenImg src={HomeScreenImage} />
                <HelpTextMobile>If you need assistance or have any questions, please feel free to email us at <a style={{
                    fontWeight: 700,
                    color: "#FFFFFF"
                }} target="_blank" href="mailto: help@seen.lol">help@seen.lol</a>.</HelpTextMobile>
                <TermsWrapper>
                    <TermsContentWrapper>
                        <Terms>
                            <div>Patent pending. Copyright © {new Date().getFullYear()}.</div>
                            <div style={{
                                paddingBottom: "2px",
                            }}><a style={{
                                    color: "#FFFFFF"
                                }} href="/terms">Terms</a> and <a style={{
                                    color: "#FFFFFF",
                                }} href="/privacy">Privacy Policy</a>
                            </div>
                        </Terms>
                    </TermsContentWrapper>
                </TermsWrapper>
            </ContentRightWrapper>
            </ContentWrapper>
        </Container>
    </>
  );
}
