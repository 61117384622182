import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {Wrapper} from "./PaymentCancelledPage";
import Header from "../../components/Header";
import DownloadLink from "../../components/DownloadLink";
import PaymentFeedbackContainer from "../../components/PaymentFeedbackContainer";
import styles from "./styles.module.css";
import {usePowerups} from "../../components/hooks/usePowerups";
import { useSearchParams } from 'react-router-dom';
import {Mixpanel} from "../../Mixpanel";
import settings from "../../settings";

const PaymentSuccessPage = () => {
  const [searchParams] = useSearchParams();
    const checkout_session_id = searchParams.get("checkout_session_id");

    const { clip_id, reaction_id, order_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(undefined);
    const [error, setError] = useState(undefined);
    const { handleCheckout } = usePowerups();

    let isInitialized = false;

    useEffect(() => {
        const updateOrder = async () => {
            if(!order_id) {
                setLoading(false);

                return;
            }

            try {
                const opts = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        order_id,
                        checkout_session_id,
                    }),
                };
                const response = await fetch(
                  `${settings.API_HOSTNAME}/completePaymentSession`,
                  opts
                );
              if (response.status !== 200) {
                    const err = await response.json();

                    setError(err);
                } else {
                    const result = await response.json();

                    setResult(result);
                }
            } catch(e) {
                console.log("ERROR", e);

                setError(e);
            } finally {
                setLoading(false);
            }
        }
        if(!isInitialized) {
            isInitialized = true;
            updateOrder();
            Mixpanel.track('Payment success');
        }
    }, []);
    if(loading) {
        return (<div className={styles.reactionContainer}>
            <div>Loading...</div>
        </div>)
    }
    return (<div className={styles.reactionContainer}>
        <div style={{ flex: 1, width: "100%" }}>
            <Header />
            {!error && <PaymentFeedbackContainer headerText={"Payment Complete"} descriptionText={"Your payment was successful."} />}
            {error && <PaymentFeedbackContainer headerText={"Payment Complete"} descriptionText={"Sorry, an error occurred completing your payment.  Please contact us."} />}
            <DownloadLink />
        </div>
    </div>);
}

export default PaymentSuccessPage;
