import React, { useRef, useState, useEffect } from "react";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { ref, push, set } from "firebase/database";
import { signInAnonymously } from "firebase/auth";
import styles from "./styles.module.css";
import PlayButton from "../../components/PlayButton";
import database, { auth } from "../../firebase";
import Header from "../../components/Header";
import PowerUps from "../../components/PowerUps";
import DownloadLink from "../../components/DownloadLink";
import ReactionSentSuccess from "../../components/ReactionSentSuccess";
import RecordingIndicator from "../../components/RecordingIndicator";
import {usePowerups} from "../../components/hooks/usePowerups";
import { Mixpanel } from '../../Mixpanel';
import settings from "../../settings";

const VIDEO_CONFIG = {
  width: 720,
  height: 1280,
  facingMode: "user",
};
const RECORDING_SAMPLE_MS = 100;

function ReactionPage() {
  const { clip_id, username } = useParams();
  let isInitialized = false;
  const [loadingError, setLoadingError] = useState(false);
  const [loadingReactionDetails, setLoadingReactionDetails] = useState(true);
  const [errorBadDevice, setErrorBadDevice] = useState(false);
  const [clipReactionDetails, setClipReactionDetails] = useState(null);
  const [fullPermissions, setFullPermissions] = useState(false);
  const [finishedWatching, setFinishedWatching] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [recorderReady, setRecorderReady] = useState(false);
  const [anonUid, setAnonUid] = useState(null);
  const [permissionsDenied, setPermissionsDenied] = useState(false);
  const [progress, setProgressLoaded] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");
  const [muteVideo, setMuteVideo] = useState(true);

  const heartbeat = useRef(0);
  const webcamRef = useRef(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const videoDurationRef = useRef(0);

  // NOTE  a react ref to a firebase ref
  const chunksRefRef = useRef(null);
  const { handleCheckout, loadingStripe } = usePowerups();

  const playFirstThenRequest = async () => {
    videoRef.current.play();
    setTimeout(() => {
      videoRef.current.pause();
    }, 1000);
    requestPermission();
  };

  const requestPermission = async () => {
    const constraints = {
      audio: true,
      video: {
        width: { ideal: 720 },
        height: { ideal: 1280 },
      },
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setFullPermissions(true);
      stream.getTracks().forEach((track) => track.stop());

      Mixpanel.track('Permissions granted');
    } catch (error) {
      setPermissionsDenied(true);
      Mixpanel.track('Permissions denied');
      console.error("Error accessing camera and microphone:", error);
    }
  };

  const startRecordingReaction = () => {
    if (playing || !mediaRecorderRef.current) {
      // NOTE: can only play once
      return;
    }

    videoRef.current.currentTime = 0;

    setPlaying(true);
    setMuteVideo(false);

    Mixpanel.track('Start recording reaction');
    mediaRecorderRef.current.start(RECORDING_SAMPLE_MS);
  };

  const stopRecording = () => {
    setFinishedWatching(true);

    Mixpanel.track('Stop recording reaction');
  };

  const blobToBase64 = (blob, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const persistChunk = async (e) => {
    if (e.data && e.data.size > 0) {
      const chunkB64 = await blobToBase64(e.data);
      const newChunkRef = push(chunksRefRef.current);
      set(newChunkRef, chunkB64)
      if (heartbeat.current % 10 === 0) {
        console.log(`Updating heartbeat: reaction_id:${clipReactionDetails.reaction_id}, clip_id:${clip_id}`)
        const opts = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            reaction_id: clipReactionDetails.reaction_id,
            clip_id,
          }),
        };
        await fetch(
          `${settings.API_HOSTNAME}/updateReactionHeartBeat`,
          opts
        );
      }
      heartbeat.current = heartbeat.current + 1;
    }
  };

  const setupRecorder = (mediaStream) => {
    let options;
    if (MediaRecorder.isTypeSupported("video/webm; codecs=avc1")) {
      options = { mimeType: "video/webm; codecs=avc1" };
    } else if (MediaRecorder.isTypeSupported("video/mp4; codecs=avc1")) {
      options = { mimeType: "video/mp4; codecs=avc1" };
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
      options = { mimeType: "video/webm" };
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
      options = { mimeType: "video/mp4" };
    } else {
      console.error("Device has no supported codecs");
      return setErrorBadDevice(true);
    }

    options.videoBitsPerSecond = 5000000; // 5Mb/s
    options.audioBitsPerSecond = 96000; // 96Kb/s

    try {
      const _mediaRecorder = new MediaRecorder(mediaStream, options);
      _mediaRecorder.ondataavailable = persistChunk;
      mediaRecorderRef.current = _mediaRecorder;
      chunksRefRef.current = ref(
        database,
        `clip_reactions/${clipReactionDetails.reaction_id}/chunks`
      );
      setRecorderReady(true);
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      return setErrorBadDevice(true);
    }
  };

  async function loadVideoClip(url) {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("progress", function(event) {
      const _progress = (event.loaded / event.total) * 100;
      setProgressLoaded(_progress);
    });
    xhr.addEventListener("load", function(event) {
      setVideoSrc(URL.createObjectURL(xhr.response));
    });
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  useEffect(() => {
    if (!videoRef.current) { return; }

    if (playing) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    // FIXME: move this to after the permission grant
    async function initializeClipReaction() {
      try {
        const creds = await signInAnonymously(auth);
        const { uid } = creds.user;
        setAnonUid(uid);

        const opts = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ clip_id, anon_uid: uid }),
        };
        setLoadingReactionDetails(true);
        const res = await fetch(
            `${settings.API_HOSTNAME}/updateReactionHeartBeat`,
            opts
        );
        if (res.status !== 200) {
          const err = await res.json();
          console.log("API error: ", err);
          return setLoadingError(true);
        }
        // NOTE: before we can write to our part of the clip_reactions
        // collection, we need to reload our anon auth user who now has
        // permissions
        await auth.currentUser.getIdToken(true);
        const data = await res.json();
        console.log("Initialized reaction...", data.reaction_id);
        setClipReactionDetails(data);
        await loadVideoClip(data.clear_url);
      } catch (e) {
        console.log("Error initializing reaction: ", e);
        setLoadingError(true);
      } finally {
        setLoadingReactionDetails(false);
      }
    }
    if(!isInitialized) {
      isInitialized = true;
      initializeClipReaction();
    }
  }, []);

  function renderReaction() {
    if (loadingReactionDetails) {
      // TODO: ugly
      return <div>Loading reaction...</div>;
    } else if (loadingError) {
      // TODO: ugly
      return <div>Error loading this clip.</div>;
    } else if (errorBadDevice) {
      // TODO: ugly
      return <div>Sorry, we can't record on your device.</div>;
    } else if (!fullPermissions) {
      return (
        <div
          style={{
            flex: 1,
            margin: 16,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={playFirstThenRequest}
            className={styles.permissionModal}
            style={{ padding: 16, alignSelf: "flex-end" }}
          >
            <div
              style={{
                marginBottom: 52,
                textAlign: "center",
              }}
            >
              <h1 style={{ fontSize: "1.4rem" }}>Update Permission</h1>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "0.9rem",
                  marginBottom: 16,
                }}
              >
                To get your reaction, we would like to record your screen as you
                watch the video.
              </div>
              {!permissionsDenied &&<button
                className={styles.permissionButton}
              >
                Okay
              </button>}
              {permissionsDenied && <div className={styles.permissionDenied}>
                Please allow permissions to your camera and microphone by updating the settings for this website.
              </div>}
            </div>
          </div>
        </div>
      );
    } else if (!finishedWatching) {
      return (
        <div className={styles.videoContainers}>
          <div
            className={styles.reactionPreview}
            style={{ borderRadius: 16, overflow: "hidden" }}
          >
            <Webcam
              poster="/cameraPreview.gif"
              audio={true}
              ref={webcamRef}
              muted={true}
              mirrored={true}
              width={VIDEO_CONFIG.width}
              height={VIDEO_CONFIG.height}
              onUserMedia={setupRecorder}
              style={{ objectFit: "cover" }}
            />
            {playing && (
              <div className={styles.recordingIndicator}>
                <RecordingIndicator />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ flex: 1, width: "100%" }}>
          <Header />
          <ReactionSentSuccess clipId={clip_id} reactionId={clipReactionDetails.reaction_id} username={username} />
          <PowerUps clipId={clip_id} reactionId={clipReactionDetails.reaction_id} handleCheckout={handleCheckout} loadingStripe={loadingStripe} />
          <DownloadLink />
        </div>
      );
      return (
        <>
          <div>Thanks. Buy something.</div>
          <div>
            In ~2 min{" "}
            <a
              href={`${settings.IMAGE_URL_BASE}/composed_renderings/${clip_id}/${clipReactionDetails.reaction_id}.mp4`}
              target="_blank"
              rel="noreferrer"
            >
              your video will be here.
            </a>
          </div>
        </>
      );
    }
  }

  const videoCanBePlayed = mediaRecorderRef.current && recorderReady;
  const playButtonStyle = videoCanBePlayed
        ? styles.playButton
        : styles.playButtonDisabled;
  const playOnClick = videoCanBePlayed
        ? startRecordingReaction
        : () => {};

  return <div className={styles.reactionContainer}>
    <div className={!finishedWatching && fullPermissions ? styles.videoPlayer : styles.hidePlayer}>
      <video
        src={videoSrc}
        ref={videoRef}
        className={styles.clipContainer}
        poster={clipReactionDetails && clipReactionDetails.blur_url}
        onClick={playOnClick}
        onEnded={stopRecording}
        muted={muteVideo}
        playsInline
      />
      {!playing && (
        <div className={playButtonStyle} onClick={playOnClick}>
          <div className={styles.progressGroup}>
            <PlayButton />
            { progress < 100 && (
              <progress max="100" value={progress}></progress>
            )}
          </div>
        </div>
      )}
    </div>

    {renderReaction()}
  </div>;
}

export default function ReactionPageWrapper() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (!isMobile) {
    return (<div>
            <p>Please open this link on your phone to view and react to the video.</p>
            </div>);
  }

  return (
    <DeviceOrientation lockOrientation={"portrait"}>
      {/* Will only be in DOM in landscape */}
      <Orientation orientation="landscape" alwaysRender={false}>
        <div>
          <p>Rotate your device into portrait mode.</p>
        </div>
      </Orientation>
      {/* Will stay in DOM, but is only visible in portrait */}
      <Orientation orientation="portrait" alwaysRender={false}>
        <ReactionPage />
      </Orientation>
    </DeviceOrientation>
  );
}
