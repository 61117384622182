import React from "react";

const ReactionSentSuccess = (props) => {
  const { clipId, reactionId, username } = props;
  const blurredUrl = `${window.location.origin}/${username}/${clipId}/reactions/${reactionId}`;
  return (<div style={{ padding: 32 }}>
    <h1>Success!</h1>
    <p style={{ color: "#C9FF58" }}>
      <p style={{ fontSize: 18 }}>Your reaction has been sent!</p>
      <p style={{ fontSize: 13 }}>Once ready, you can view your reaction <a href={blurredUrl} target="_blank" style={{color: "#ffffff"}}>here.</a></p>
      {/* <a
          href={`https://storage.googleapis.com/radioheadstaging.appspot.com/composed_renderings/${clip_id}/${clipReactionDetails.reaction_id}.mp4`}
          target="_blank"
          style={{ color: "#C9FF58" }}
          >
          It will be here
          </a>{" "}
          In ~2 min */}
    </p>
  </div>);
};

export default ReactionSentSuccess;
