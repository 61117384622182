export default function PlayButton() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
      <g filter="url(#filter0_b_387_6339)">
        <circle cx="36" cy="36" r="36" fill="white" fillOpacity="0.3"/>
      </g>
      <path d="M31 50C29.2884 50 28 48.6005 28 47V25C28 23.4138 29.2747 22.0011 31.0007 22.0011C31.5436 22.0011 32.0854 22.148 32.5644 22.4404L50.5644 33.4404C51.4561 33.9854 52 34.9551 52 36C52 37.0449 51.4561 38.0146 50.5644 38.5596L32.5644 49.5596C32.0849 49.8526 31.543 50 31 50Z" fill="#C9FF58"/>
      <defs>
        <filter id="filter0_b_387_6339" x="-40" y="-40" width="152" height="152" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_387_6339"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_387_6339" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}
