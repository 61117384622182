const devSettings = {
  IMAGE_URL_BASE: "https://storage.googleapis.com/radiohead-db197.appspot.com",
  API_HOSTNAME: "https://us-central1-radiohead-db197.cloudfunctions.net",
  STRIPE_REACTION_NOTIFICATION_POWER_UP_PROD_ID: "prod_NSBUo9jqeWpbzQ",
  STRIPE_UNSEND_REATION_POWER_UP_PROD_ID: "prod_NSBVyVmXKpZ1hz",
  firebaseConfig: {
    apiKey: "AIzaSyB-5V3-spOR__cqXtz7EfITIUnaw0yvItY",
    authDomain: "radiohead-db197.firebaseapp.com",
    projectId: "radiohead-db197",
    storageBucket: "radiohead-db197.appspot.com",
    messagingSenderId: "122664619762",
    appId: "1:122664619762:web:4269791c2f87a428254a6b",
    measurementId: "G-ZV0WMSZF62"
  }
};
const prodSettings = {
  IMAGE_URL_BASE: "https://storage.googleapis.com/radioheadstaging.appspot.com",
  API_HOSTNAME: "https://us-central1-radioheadstaging.cloudfunctions.net",
  STRIPE_REACTION_NOTIFICATION_POWER_UP_PROD_ID: "prod_NZyyCtsrnviNOq",
  STRIPE_UNSEND_REATION_POWER_UP_PROD_ID: "prod_NZyyljRUzT0eGH",
  firebaseConfig: {
    apiKey: "AIzaSyCIsW8FZR8SXz-3qnOlupwBV0-U1JlarM8",
    authDomain: "radioheadstaging.firebaseapp.com",
    databaseURL: "https://radioheadstaging-default-rtdb.firebaseio.com",
    projectId: "radioheadstaging",
    storageBucket: "radioheadstaging.appspot.com",
    messagingSenderId: "273208998891",
    appId: "1:273208998891:web:4a7541f4a77918c69cb7ae",
    measurementId: "G-5J1DB2B9Q4"
  }
};
let settings = devSettings;
if (process.env.REACT_APP_SEEN_BUILD_ENV == "production") {
  console.log('---------building production=-------')
  settings = prodSettings;
}
export default settings;
