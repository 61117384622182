import mixpanel from 'mixpanel-browser';
mixpanel.init('689814dd5be8e83b07d3c34b64b10d64');

let env_check = process.env.NODE_ENV === 'production';

let actions = {
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
};

export let Mixpanel = actions;