export default function RecordingIndicator() {
  return (
    <svg width="42" height="18" viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="9" r="3.5" fill="#D70015"/>
      <path d="M15.91 13V5.8H18.885C18.955 5.8 19.045 5.80333 19.155 5.81C19.265 5.81333 19.3666 5.82333 19.46 5.84C19.8766 5.90333 20.22 6.04167 20.49 6.255C20.7633 6.46833 20.965 6.73833 21.095 7.065C21.2283 7.38833 21.295 7.74833 21.295 8.145C21.295 8.73167 21.1466 9.23667 20.85 9.66C20.5533 10.08 20.0983 10.34 19.485 10.44L18.97 10.485H17.115V13H15.91ZM20.045 13L18.625 10.07L19.85 9.8L21.41 13H20.045ZM17.115 9.36H18.835C18.9016 9.36 18.9766 9.35667 19.06 9.35C19.1433 9.34333 19.22 9.33 19.29 9.31C19.49 9.26 19.6466 9.17167 19.76 9.045C19.8766 8.91833 19.9583 8.775 20.005 8.615C20.055 8.455 20.08 8.29833 20.08 8.145C20.08 7.99167 20.055 7.835 20.005 7.675C19.9583 7.51167 19.8766 7.36667 19.76 7.24C19.6466 7.11333 19.49 7.025 19.29 6.975C19.22 6.955 19.1433 6.94333 19.06 6.94C18.9766 6.93333 18.9016 6.93 18.835 6.93H17.115V9.36ZM23.0115 13V5.8H27.6615V6.93H24.2165V8.705H27.0615V9.835H24.2165V11.87H27.6615V13H23.0115ZM32.1522 13.15C31.4322 13.15 30.8156 12.9933 30.3022 12.68C29.7889 12.3633 29.3939 11.9233 29.1172 11.36C28.8439 10.7967 28.7072 10.1433 28.7072 9.4C28.7072 8.65667 28.8439 8.00333 29.1172 7.44C29.3939 6.87667 29.7889 6.43833 30.3022 6.125C30.8156 5.80833 31.4322 5.65 32.1522 5.65C32.9822 5.65 33.6739 5.85833 34.2272 6.275C34.7806 6.68833 35.1689 7.24667 35.3922 7.95L34.1772 8.285C34.0372 7.815 33.7989 7.44833 33.4622 7.185C33.1256 6.91833 32.6889 6.785 32.1522 6.785C31.6689 6.785 31.2656 6.89333 30.9422 7.11C30.6222 7.32667 30.3806 7.63167 30.2172 8.025C30.0572 8.415 29.9756 8.87333 29.9722 9.4C29.9722 9.92667 30.0522 10.3867 30.2122 10.78C30.3756 11.17 30.6189 11.4733 30.9422 11.69C31.2656 11.9067 31.6689 12.015 32.1522 12.015C32.6889 12.015 33.1256 11.8817 33.4622 11.615C33.7989 11.3483 34.0372 10.9817 34.1772 10.515L35.3922 10.85C35.1689 11.5533 34.7806 12.1133 34.2272 12.53C33.6739 12.9433 32.9822 13.15 32.1522 13.15Z" fill="white"/>
      <path d="M9 1L1 0.999999L1 17L9 17" stroke="white" stroke-width="1.5"/>
      <path d="M33 17H41L41 1L33 1" stroke="white" stroke-width="1.5"/>
    </svg>
  )
}
