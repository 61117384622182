import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import styles from "./styles.module.css";
import Header from "../../components/Header";
import PowerUps from "../../components/PowerUps";
import DownloadLink from "../../components/DownloadLink";
import PaymentFeedbackContainer from "../../components/PaymentFeedbackContainer";
import {usePowerups} from "../../components/hooks/usePowerups";
import {Mixpanel} from "../../Mixpanel";
import settings from "../../settings";

export const Wrapper = (props) => {
    const { headerText, descriptionText } = props;

    return (<div className={styles.reactionContainer}><div
        style={{
            flex: 1,
            margin: 16,
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <div
            className={styles.permissionModal}
            style={{ padding: 16, alignSelf: "flex-end" }}
        >
            <div
                style={{
                    marginBottom: 52,
                    textAlign: "center",
                }}
            >
                <h1 style={{ fontSize: "1.4rem" }}>{headerText}</h1>
                <div
                    style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        marginBottom: 16,
                    }}
                >
                    {descriptionText}
                </div>

            </div>
        </div>
    </div>
    </div>)
}
const PaymentCancelledPage = () => {
    const { clip_id, reaction_id, order_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const { handleCheckout, loadingStripe } = usePowerups();

    let isInitialized = false;

    useEffect(() => {
        const updateOrder = async () => {
            if(!order_id) {
                setLoading(false);

                return;
            }
            try {
                const opts = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        order_id,
                    }),
                };
                const response = await fetch(
                  `${settings.API_HOSTNAME}/cancelPaymentSession`,
                  opts
                );
                if (response.status !== 200) {
                    const err = await response.json();

                    setError(err);
                }
            } catch(e) {
                console.log("ERROR", e);

                setError(e);
            } finally {
                setLoading(false);
            }
        }
        if(!isInitialized) {
            isInitialized = true;
            updateOrder();
            Mixpanel.track('Payment cancelled');
        }
    }, []);
    if(loading) {
        return (<div className={styles.reactionContainer}>Loading...</div>)
    }
    return (<div className={styles.reactionContainer}>
        <div style={{ flex: 1, width: "100%" }}>
            <Header />
            {!error && <PaymentFeedbackContainer headerText={"Payment Cancelled"} descriptionText={"Your payment was cancelled."} />}
            {error && <PaymentFeedbackContainer headerText={"Payment Cancelled"} descriptionText={"Sorry, an error occurred cancelling your payment.  Please contact us."} />}
            <PowerUps clipId={clip_id} reactionId={reaction_id} handleCheckout={handleCheckout} loadingStripe={loadingStripe} />
            <DownloadLink />
        </div>
    </div>);
}

export default PaymentCancelledPage;
