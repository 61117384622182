import AppStore from "../images/appstore.png";

const DownloadLink = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        >
        <p style={{ fontSize: 16 }}>Get reactions to your own videos!</p>

        <div style={{
               width: "100%",
               textAlign: "center",
               paddingBottom: 100,
             }}>
          <a href="https://apps.apple.com/us/app/seen-authentic-reactions/id6445854955" target="_blank">
            <img src={AppStore} style={{
                   marginTop: "30px",
                   width: "160px",
                 }}/></a>
        </div>

      </div>
    );
};

export default DownloadLink;
