import React, {useState} from "react";
import settings from "../../settings";

const usePowerups = () => {
    const [loadingStripe, setLoadingStripe] = useState(false);

    const handleCheckout = async(clip_id, reaction_id, productSku) => {
        try {
            setLoadingStripe(true);
            const opts = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    reaction_id,
                    clip_id,
                    product_sku: productSku,
                }),
            };
            const response = await fetch(
                `${settings.API_HOSTNAME}/initializePaymentSession`,
                opts
            );
            if (response.status !== 200) {
                const err = await response.json();
                console.log("API error: ", err);
            }
            const data = await response.json();

            window.location.href = data.redirect_to;
        } catch(e) {
            console.log(e);

            throw  e;
        } finally {
            setLoadingStripe(false);
        }
    };
    return {
        handleCheckout,
        loadingStripe
    }
}

export { usePowerups };
