import React from "react";

const Header = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
        color: "#C9FF58",
        padding: "20px 0px"
      }}
    >
      <a href="/">
        <svg width="159" height="67" viewBox="0 0 159 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M77.5553 41.6197C77.5553 43.1656 77.1054 44.4938 76.2056 45.6089C75.3058 46.7241 73.9705 47.5665 72.1972 48.136C70.4239 48.7056 68.2582 48.9904 65.7 48.9904C63.1418 48.9904 60.7727 48.6673 58.8247 48.0212C56.8743 47.375 55.3547 46.4585 54.2659 45.2667C53.177 44.0774 52.5955 42.6966 52.519 41.1267H58.9779C59.131 41.8351 59.5091 42.4501 60.117 42.9694C60.7248 43.4887 61.5169 43.8883 62.4909 44.1659C63.4649 44.4459 64.5992 44.5847 65.8914 44.5847C67.6886 44.5847 69.0503 44.3813 69.9764 43.9769C70.9001 43.5724 71.362 42.9646 71.362 42.1533C71.362 41.5718 71.0389 41.1267 70.3928 40.8228C69.7467 40.5189 68.5621 40.2915 66.8415 40.1384L62.814 39.8345C60.4065 39.631 58.4945 39.2266 57.0778 38.6188C55.6587 38.0109 54.6512 37.2452 54.0577 36.3214C53.4618 35.3977 53.1651 34.3783 53.1651 33.2631C53.1651 31.7195 53.6461 30.4273 54.6081 29.3887C55.5701 28.3501 56.9198 27.5652 58.6548 27.0339C60.3898 26.5026 62.3976 26.237 64.6758 26.237C66.954 26.237 69.0838 26.5529 70.9073 27.1871C72.7309 27.8212 74.193 28.6947 75.2962 29.8075C76.397 30.9227 77.024 32.2149 77.1772 33.6819H70.7183C70.6178 33.1506 70.333 32.6505 69.8639 32.1814C69.3949 31.7124 68.7177 31.3271 67.8322 31.0232C66.9444 30.7192 65.7933 30.5661 64.3742 30.5661C62.7781 30.5661 61.5504 30.7504 60.6889 31.1165C59.8274 31.485 59.3966 32.0354 59.3966 32.7701C59.3966 33.2774 59.6623 33.689 60.1935 34.0049C60.7248 34.3208 61.7131 34.5434 63.1562 34.6702L68.3994 35.089C70.7302 35.2924 72.5657 35.6777 73.9082 36.2472C75.2508 36.8168 76.1936 37.5586 76.7392 38.4704C77.2825 39.3822 77.5553 40.4327 77.5553 41.6245V41.6197Z" fill="white"/>
          <path d="M92.8017 48.9913C90.2435 48.9913 87.9581 48.5175 85.9456 47.5674C83.933 46.6174 82.3488 45.282 81.1977 43.559C80.0442 41.836 79.4699 39.8354 79.4699 37.5572C79.4699 35.279 80.0203 33.3286 81.1235 31.6295C82.2243 29.9329 83.7392 28.6095 85.6632 27.6594C87.5872 26.7094 89.7793 26.2356 92.2346 26.2356C94.6899 26.2356 96.8939 26.786 98.6935 27.8892C100.491 28.99 101.879 30.5431 102.853 32.5437C103.827 34.5443 104.315 36.8991 104.315 39.6104H84.56V35.3938H100.895L98.4254 36.9135C98.3249 35.6475 98.0138 34.5706 97.4945 33.6852C96.9752 32.7998 96.2717 32.1153 95.3862 31.6343C94.4984 31.1533 93.4622 30.9116 92.2705 30.9116C90.9782 30.9116 89.8582 31.1725 88.9082 31.6894C87.9581 32.2087 87.2235 32.9314 86.7042 33.8551C86.1849 34.7812 85.9264 35.8629 85.9264 37.1025C85.9264 38.5982 86.2543 39.8761 86.9148 40.9386C87.5729 42.0011 88.5349 42.8124 89.8008 43.37C91.0667 43.9275 92.5983 44.2051 94.3979 44.2051C96.018 44.2051 97.6525 43.9706 99.2989 43.5016C100.945 43.0325 102.427 42.3673 103.743 41.5081V45.763C102.324 46.7753 100.673 47.5674 98.7844 48.137C96.8963 48.7065 94.9028 48.9913 92.8017 48.9913Z" fill="white"/>
          <path d="M119.7 48.9913C117.142 48.9913 114.856 48.5175 112.844 47.5674C110.831 46.6174 109.247 45.282 108.096 43.559C106.942 41.836 106.368 39.8354 106.368 37.5572C106.368 35.279 106.918 33.3286 108.022 31.6295C109.122 29.9329 110.637 28.6095 112.561 27.6594C114.485 26.7094 116.677 26.2356 119.133 26.2356C121.588 26.2356 123.792 26.786 125.591 27.8892C127.389 28.99 128.777 30.5431 129.751 32.5437C130.725 34.5443 131.213 36.8991 131.213 39.6104H111.458V35.3938H127.793L125.323 36.9135C125.223 35.6475 124.912 34.5706 124.393 33.6852C123.873 32.7998 123.17 32.1153 122.284 31.6343C121.396 31.1533 120.36 30.9116 119.168 30.9116C117.876 30.9116 116.756 31.1725 115.806 31.6894C114.856 32.2087 114.121 32.9314 113.602 33.8551C113.083 34.7812 112.824 35.8629 112.824 37.1025C112.824 38.5982 113.152 39.8761 113.813 40.9386C114.471 42.0011 115.433 42.8124 116.699 43.37C117.965 43.9275 119.496 44.2051 121.296 44.2051C122.916 44.2051 124.55 43.9706 126.197 43.5016C127.843 43.0325 129.325 42.3673 130.641 41.5081V45.763C129.222 46.7753 127.571 47.5674 125.682 48.137C123.794 48.7065 121.801 48.9913 119.7 48.9913Z" fill="white"/>
          <path d="M133.312 26.8051H139.656L141.024 35.0492V48.4217H134.68V34.0992L133.312 26.8051ZM149.269 26.2356C151.319 26.2356 153.069 26.6472 154.512 27.4704C155.955 28.2936 157.058 29.4638 157.817 30.9834C158.575 32.503 158.956 34.3146 158.956 36.4157V48.4217H152.612V37.3657C152.612 35.4154 152.143 33.9413 151.207 32.9386C150.269 31.9383 148.902 31.4381 147.103 31.4381C145.887 31.4381 144.817 31.7109 143.894 32.2541C142.968 32.7998 142.259 33.5536 141.766 34.5156C141.273 35.4776 141.024 36.6311 141.024 37.9736L138.935 36.8728C139.189 34.5922 139.797 32.6681 140.759 31.0983C141.721 29.5284 142.936 28.3247 144.406 27.4895C145.875 26.6543 147.495 26.2356 149.269 26.2356Z" fill="white"/>
          <path d="M10.9431 18.2093L35.1293 6.50509C36.0271 10.3231 34.1204 14.382 30.5105 16.0779L22.2077 20.3314L10.9523 18.2L10.9431 18.2093Z" fill="#FFF200"/>
          <path d="M15.5614 66.9998C12.9419 66.9998 10.4612 65.7673 8.8877 63.7193L40.1549 48.5122C42.3393 47.4929 44.1628 45.9731 45.4956 44.0548C45.653 44.5923 45.7641 45.1391 45.8289 45.6951C46.3657 50.0876 44.1257 54.128 40.1271 55.9907L18.366 66.5179C17.5237 66.8237 16.5518 66.9906 15.5706 66.9906L15.5614 66.9998Z" fill="#F963BC"/>
          <path d="M10.1103 38.6433C5.74138 37.8 2.56654 34.3991 2.03894 29.9787C1.9834 29.5154 1.95563 29.052 1.96489 28.5979C3.68653 30.025 5.7599 30.9888 8.04616 31.4244L34.1762 36.3729L24.1518 41.3029L10.101 38.6433H10.1103Z" fill="#30CACA"/>
          <path d="M7.39783 60.6522C6.41668 56.8064 8.27716 52.6826 11.9426 50.9405L35.8697 39.3475C37.2303 38.7173 37.943 37.4478 37.7579 35.9373C37.6931 35.372 37.2951 33.5 35.0921 33.0738L8.68443 28.0696C4.30629 27.2356 1.1407 23.8346 0.613097 19.4143C0.0854984 14.9939 2.3625 10.9443 6.41668 9.10015L23.3461 0.796958C24.4754 0.268742 25.6787 0 26.9097 0C29.5755 0 32.0746 1.26957 33.6574 3.37317L9.22129 15.1885C7.82361 15.828 7.09238 17.1068 7.26825 18.6173C7.45337 20.1278 8.44377 21.2028 9.93401 21.4808L36.3417 26.485C40.7013 27.319 43.8669 30.7107 44.4038 35.131C44.9406 39.5143 42.7007 43.564 38.702 45.4266L7.39783 60.6522Z" fill="#C9FF58"/>
        </svg>
      </a>
    </div>
  )
};

export default Header;
