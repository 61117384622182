import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BrowserDetection from 'react-browser-detection';

// Pages
import HomePage from "./pages/HomePage/HomePage";
import ReactionPage from "./pages/ReactionPage/ReactionPage";
import TermsPage from "./pages/Legal/TermsPage";
import PrivacyPage from "./pages/Legal/PrivacyPage";
import CopyrightPage from "./pages/Legal/CopyrightPage";
import FomoPage from "./pages/ReactionPage/FomoPage";
import PaymentSuccessPage from "./pages/ReactionPage/PaymentSuccessPage";
import PaymentCancelledPage from "./pages/ReactionPage/PaymentCancelledPage";
import ViewReactionPage from "./pages/ReactionPage/ViewReactionPage";

const browserHandler = {
  // FIXME: we need better special casing. basically all non-mobile browsers are the FOMO browser
  firefox: () => (
    <Router>
      <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="/:username/:clip_id" element={<FomoPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/copyright" element={<CopyrightPage />} />
      </Routes>
    </Router>
  ),
  default: () => (
    <Router>
      <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="/:username/:clip_id" element={<ReactionPage />} />
        <Route path="/:username/:clip_id/reactions/:reaction_id" element={<ViewReactionPage />} />
        <Route path="/orders/:clip_id/:reaction_id/:order_id/success" element={<PaymentSuccessPage />} />
        <Route path="/orders/:clip_id/:reaction_id/:order_id/cancel" element={<PaymentCancelledPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/copyright" element={<CopyrightPage />} />
      </Routes>
    </Router>
  )
};
function App() {
  return (
    <BrowserDetection>
      { browserHandler }
    </BrowserDetection>
  );
}

export default App;
