import React from "react";

const PaymentFeedbackContainer = (props) => {
    const { headerText, descriptionText } = props;

    return (<div style={{ padding: 32 }}>
        <h1>{headerText}</h1>
        <p style={{ color: "#C9FF58" }}>
            <p>{descriptionText}</p>
            {/* <a
                href={`https://storage.googleapis.com/radioheadstaging.appspot.com/composed_renderings/${clip_id}/${clipReactionDetails.reaction_id}.mp4`}
                target="_blank"
                style={{ color: "#C9FF58" }}
              >
                It will be here
              </a>{" "}
              In ~2 min */}
        </p>
    </div>);
};

export default PaymentFeedbackContainer;