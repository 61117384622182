import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInterval } from "../../components/hooks/useInterval";
import styles from "./styles.module.css";
import settings from "../../settings";
import Header from "../../components/Header";
import PlayButton from "../../components/PlayButton";
import DownloadLink from "../../components/DownloadLink";
import {Mixpanel} from "../../Mixpanel";

export default function ViewReactionPage() {
  let isInitialized = false;
  const [progress, setProgress] = useState(0);
  const [videoSrc, setVideoSrc] = useState('');
  const [videoPoster, setVideoPoster] = useState('/videoLoading.gif');
  const [playing, setPlaying] = useState(false);
  const [notRenderedYet, setNotRenderedYet] = useState(true);
  const { clip_id, reaction_id } = useParams();
  const videoRef = useRef(null);

  async function loadRenderedReaction() {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("progress", function(event) {
      const _progress = (event.loaded / event.total) * 100;
      setProgress(_progress);
    });
    xhr.addEventListener("load", function(event) {
      if (xhr.status === 403) {
        setNotRenderedYet(true);
      } else {
        setNotRenderedYet(false);
        setVideoSrc(URL.createObjectURL(xhr.response));
        setVideoPoster("");
      }
    });
    xhr.open("GET", `${settings.IMAGE_URL_BASE}/composed_renderings/${clip_id}/blur_${reaction_id}.mp4`);
    xhr.responseType = "blob";
    xhr.send();
  };
  useInterval(() => {
    if(videoSrc) { return }

    loadRenderedReaction();
  }, 5000);
  useEffect(() => {
    if(!isInitialized) {
      isInitialized = true;
      loadRenderedReaction();
    }
  }, []);

  const play = () => {
    if (playing) { return }
    Mixpanel.track('Play your reaction clicked');
    setPlaying(true);
    videoRef.current.play();
  };

  const videoCanBePlayed = videoSrc !== "";
  const playButtonStyle = videoCanBePlayed
                        ? styles.playButton
                        : styles.playButtonDisabled;

  return (
    <div className={styles.viewReactionPage}>
      <Header />
      {notRenderedYet && (
        <>
          <h2 style={{ color: "#ffffff" }}>Hang tight!</h2>
          <h3 style={{ color: "#ffffff" }}>Your reaction is almost ready...</h3>
        </>
      )}
      <div className={styles.viewReactionContainer}>
        <video
          src={videoSrc}
          ref={videoRef}
          className={styles.viewReactionVideoContainer}
          poster={videoPoster}
          onClick={play}
          playsInline
        />
        {!playing && videoCanBePlayed && (
          <div className={styles.viewReactionPlayButtonContainer} onClick={play}>
            <PlayButton />
            { progress < 100 && (
                <progress max="100" value={progress}></progress>
              )}
          </div>
        )}
      </div>
      <DownloadLink />
    </div>
  )
}
