import React from "react";
import styled from "styled-components";
import {Mixpanel} from "../Mixpanel";
import settings from "../settings";

const PowerUpButton = styled.button`
  background-color: #C9FF58;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 12px;
  margin-bottom: 16px;
  color: #000000;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
`;
const PowerUpCopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding: 12px 16px;
  text-align: left;
`;
const PowerUpPricing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 72px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0px 12px 12px 0px;
`;
const PowerUpHeaderText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;
const PowerUpDescriptionText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #313033;
`;
const PowerUoPriceText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`;

const PowerUps = (props) => {
    const { clipId, reactionId, handleCheckout, loadingStripe } = props;

  return (<div style={{ padding: 16, paddingTop: 0 }}>
        <h3>Powerups</h3>
        <PowerUpButton disabled={loadingStripe} onClick={async() => {
            Mixpanel.track('Unsend your reaction powerup clicked');
            await handleCheckout(clipId, reactionId, `${settings.STRIPE_UNSEND_REATION_POWER_UP_PROD_ID}`);
        }}>
            <PowerUpCopyWrapper>
                <PowerUpHeaderText>Unsend your reaction.</PowerUpHeaderText>
                <PowerUpDescriptionText>Remove your reaction so the creator doesn't see it.</PowerUpDescriptionText>
            </PowerUpCopyWrapper>
            <PowerUpPricing>
                <PowerUoPriceText>$0.99</PowerUoPriceText>
            </PowerUpPricing>
        </PowerUpButton>
        <PowerUpButton disabled={loadingStripe} onClick={async() => {
            Mixpanel.track('Reaction notification powerup clicked');
            await handleCheckout(clipId, reactionId, `${settings.STRIPE_REACTION_NOTIFICATION_POWER_UP_PROD_ID}`);
        }}>
            <PowerUpCopyWrapper>
                <PowerUpHeaderText>Reaction notification</PowerUpHeaderText>
                <PowerUpDescriptionText>Get notified when the creator views your reaction.</PowerUpDescriptionText>
            </PowerUpCopyWrapper>
            <PowerUpPricing>
                <PowerUoPriceText>$0.99</PowerUoPriceText>
            </PowerUpPricing>
        </PowerUpButton>
    </div>);
};

export default PowerUps;
